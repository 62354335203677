.task-field {
	display: inline-block;
	padding: 4px 8px;
	border-radius: 4px;
	/* margin-right: 8px;*/
	/*margin: 2px 0px 2px 0px;*/
	margin: 2px 1px;
	border: 1px solid rgba(128, 128, 128, 0.1);
	background-color: transparent;

	text-align: center;
}

.task-field:focus {
	outline: none;
	box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.5);
}

.task-select {
	flex: 0 0 30px;
}

.task-title span {
	padding-left: 10px; /* Add padding inside the border */
}
.task-title {
	flex: 1 1 auto;
	font-size: 1.2em;
	text-align: left;
}

.task-due-date {
	flex: 0 0 160px;
	text-align: right;
}

.task-priority {
	flex: 0 0 60px;
}

.task-time-estimate {
	flex: 1 1 60px;
	/*
    display: flex;
    align-items: center;
    */
}

.task-done {
	flex: 0 0 30px;
}
.task-delete {
	flex: 0 0 30px;
}
