.area-item {
	display: flex;
	align-items: center;
}

.area-item.selected {
	font-weight: bold;
}

.star-icon {
	margin-right: 5px;
	cursor: pointer;
	color: #ccc;
}

.star-icon.selected {
	color: gold;
}

.area-list-tasks {
	list-style-type: none;
	padding-left: 0;
}

.area-item {
	display: flex;
	align-items: center;
}
