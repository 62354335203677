/* task-component */
.task {
	border: 1px solid #ededed;
	border-radius: 5px;
	display: contents;
}

.task-done,
.task-delete {
	border: 1px solid #ededed;
	border-radius: 5px;
	cursor: pointer;
	margin: 2px 1px;
}
.task-delete {
	background-color: #ffdddd;
}
.task-done {
	background-color: white;
}

.task-done:hover,
.task-delete:hover {
	background-color: rgba(
		255,
		255,
		255,
		0.7
	); /* Slightly less transparent on hover */
}

.flash-error {
	animation: flash 0.3s;
}

@keyframes flash {
	0% {
		background-color: red;
	}
	100% {
		background-color: transparent;
	}
}
