.task-row {
	align-items: center;
	width: 100%;
}

.task {
}

.task-list-header {
	margin: 0px;
}

.task-row {
	margin: 0px;
	display: contents;
}
.task-row input {
	margin: 0px;
	margin-right: 5px;
}

.task-grid {
	display: grid;
	grid-template-columns: auto auto 1fr auto auto auto auto;
}

.task-list {
	display: flex;
	flex-direction: column;
}

.task-header {
	font-weight: bold;
}

.task-list-header span {
	margin: 3px 5px 0px 5px;
	font-weight: bold;
}
