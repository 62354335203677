.color-icon-container {
	position: relative;
	display: inline-block;
	cursor: pointer;
}

.color-icon-container .remove-color-icon {
	position: absolute;
	top: -2px;
	right: -2px;
	font-size: 0.6em;
	color: #ff0000;
	background-color: white;
	border-radius: 50%;
}
